import 'jquery';
// import 'jquery.cookie';
import 'popper.js'
import 'bootstrap'

import './components/slider';
import './components/general';
import './components/units_filter';
import './components/units';
//import './components/imaps';
import './components/gallery';
