import $ from 'jquery';

const SORT = 'sort';
const DIRECTION = 'direction';

$(document).ready(function () {

  const $units_filter_form = $('form[name="units_filter"]');

  let triggerFilter = function () {
    const $this = $(this);
    const entity_type = $this.data('entity-type');
    const value = $this.data('value');
    const $input = $(`input[name="units_filter[${entity_type}]"]`);
    $this.closest('.dropdown').find('.units_filter__select-box__label').text($this.text());
    // set regular value
    $input.val(value);
    if (entity_type === SORT) {
      // set direction for hidden input if sort was triggered
      const direction = $this.data(DIRECTION);
      $(`input[name="units_filter[${DIRECTION}]"]`).val(direction);
    }
    if ($(this).closest('ul').hasClass('submit-on-change') || $(this).hasClass('submit-on-change')) {
      $units_filter_form.submit();
    }
  };
  $(".units_filter__dropdown").on('click', 'li', triggerFilter);
  $(".units_filter__checkbox").on('click', triggerFilter);
});
