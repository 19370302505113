import $ from 'jquery'
import Glide, {Breakpoints, Controls} from '@glidejs/glide'
import Swiper from 'swiper'
import GLightbox from 'glightbox';

// import 'owl.carousel/dist/assets/owl.carousel.min.css';
// import 'owl.carousel';

// var owlCarousel = require('owl.carousel');
$(document).ready(function () {
  // for banners

  var sliders = $('.glide-enabled');

  for (var i = 0; i < sliders.length; i++) {
    var glide = new Glide(sliders[i], {
      type: 'carousel',
      // startAt: 0,
      perView: 1,
      autoplay: 10000, // 10 sec
      hoverpause: true,
      animationDuration: 1000
    });
    glide.mount();
  }

  const lightbox = GLightbox('.glightbox', {
    // height: 'auto',
  });
  // new Glide('.glide-image-list', {
  //   type: 'carousel',
  //   // startAt: 0,
  //   // perView: 6,
  // }).mount({Controls, Breakpoints});
  let isInit = false;
  // const timerId = setInterval(function () {
  //   // gallery cannot be init properly if $el is not visible
  //   // so this is used to make sure that gallery
  //   // is init once different tab is opened
  //   isInit = initGallery('.swiper-container');
  //   if (isInit) {
  //     clearInterval(timerId);
  //   }
  // }, 1000);

  // image preview
  $('.swiper-slide').on('click', function () {
    $('.imagepreview').attr('src', $(this).data('image-src'));
    $('#imagemodal').modal('show');
  });

  function initGallery(className) {
    const $el = $(className);
    if ($el.length === 0) {
      return true;
    }
    if (!$el.is(":visible")) {
      return false;
    }

    const lightbox = GLightbox('.glightbox', {
      height: 'auto',
    });
    // new Glide('.glide-image-list', {
    //   type: 'carousel',
    //   // startAt: 0,
    //   perView: 6,
    // }).mount({Controls, Breakpoints});
    return true;
    // for galleries
    new Swiper(className, {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      // autoHeight: true,
      centerInsufficientSlides: true,
      // slidesOffsetBefore: 100,
      initialSlide: $('.swiper-container .swiper-slide').length / 2,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
      coverflowEffect: {
        rotate: 10,
        stretch: 500,
        depth: 500,
        modifier: 1,
        slideShadows: false,
      },
      on: {
        init: function () {
          $(".swiper-slide").css({"opacity": 1});
        },
      }
    });
    return true;
  }
});
