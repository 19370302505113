$( document ).ready(function() {

  //CONTACT FORM MODAL
  //if any dom element has id of #getContactUsForm append modal
  if ($('#getContactUsForm')[0] != null){
    let getUrl = window.location;
    let targetUrl = getUrl .protocol + "//" + getUrl.host + "/units/getContactForm"
    jQuery.ajax({
      url: targetUrl,
      type: 'GET',
      success: function(response) {
        $('body').append(response);
      },
      error: function() {
        alert("We are very sorry but we could not get the contact form for you. Please use the email or phone number to contact us. We are sorry for the inconvenience.");
      }
    });
  }

  //TAB HISTORY
  let navTabs = $('.nav-link ');
  let tabs = $('.tab-pane ');

  //banners
  let mainBanner = $('#banner-main');
  let lastActiveBanner;

  //init refresh
  refreshTabs();
  refreshBreadCrumbs();
  resetBannersAfterLoad();
  refreshBanners();

  //on click subpages tab
  navTabs.on('click', function (e) {
    let target = $(e.currentTarget);
    let tabId = target.data('id');
    if (target.data('toggle') === "tab"){
      if (window.location.hash){
        history.pushState(null, null, location.href.replace(location.hash, target[0].hash));
      }else{
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search + target[0].hash;
        history.pushState(null, null, newUrl);
      }
      refreshBreadCrumbs(true, e.currentTarget);
    }
    //banners
    if ($(('#banner-'+tabId)).length){
      mainBanner.hide();
      if (lastActiveBanner){
        lastActiveBanner.hide();
      }
      lastActiveBanner = $(('#banner-'+tabId));
      lastActiveBanner.show();
    }else{
      mainBanner.show();
      if (lastActiveBanner){
        lastActiveBanner.hide();
      }
    }
  });

  //on back proc tabs
  window.onpopstate = function(event) {
    refreshTabs();
    refreshBreadCrumbs(true);
    refreshBanners();
  };

  var updateBannerHeight = function () {
    // if the window is below sm we need to calculate the banner height manually
    if ($(window).width() < 575) {
      $('.glide__slide').find('a').add('.page-banner').css('height', $(window).width() / (1140 / 618) + 'px');
    } else {
      $('.glide__slide').find('a').add('.page-banner').css('height', '');
    }
  };

  updateBannerHeight();
  $(updateBannerHeight);
  $(window).resize(updateBannerHeight);

  function refreshTabs(){
    // do not attempt to refresh if no elements
    if(!navTabs || !tabs || navTabs.length !== tabs.length || navTabs.length === 0 || tabs.length === 0){
      return false;
    }

    //if default tab show first tab
    if (!location.hash){
      navTabs.removeClass('active');
      tabs.removeClass('active show');
      navTabs.first().addClass('active');
      $(navTabs.first().attr("href")).addClass('active show');
      return false;
    }

    navTabs.each(function (k, i) {
      i = $(i);
      if(i.attr("href") === location.hash){
        navTabs.removeClass('active');
        tabs.removeClass('active show');
        i.addClass('active');
        $(i.attr("href")).addClass('active show');
        return false;
      }
    });
  }

  //SUBPAGES BREADCRUMBS
  function refreshBreadCrumbs(useOld = false, ref = null){
    // do not attempt to refresh if no elements
    if(!navTabs || !tabs || navTabs.length !== tabs.length || navTabs.length === 0 || tabs.length === 0){
      return false;
    }

    let breadCrumbs = $('.breadcrumbs');
    let lastBreadCrumb = $('.breadcrumbs__item');
    lastBreadCrumb =  lastBreadCrumb[lastBreadCrumb.length-1];
    let newLink = useOld ? $(lastBreadCrumb) : $(lastBreadCrumb).clone();
    let targetLink = ref ? $(ref) : $('.nav-link.active');
    newLink.children('.breadcrumbs__link').html(targetLink.html());
    newLink.children('.breadcrumbs__link').attr("href", targetLink.attr("href"));
    ref ? null : newLink.appendTo(breadCrumbs);
  }

  //BANNERS REFRESH and RESET
  function resetBannersAfterLoad(){
    if (!navTabs || !tabs || navTabs.length !== tabs.length || navTabs.length === 0 || tabs.length === 0) {
      return false;
    }
    let glides = $('.page-banner');
    glides.hide();
  }
  function refreshBanners(){
    if(!navTabs || !tabs || navTabs.length !== tabs.length || navTabs.length === 0 || tabs.length === 0){
      return false;
    }

    let targetBannerId = "#banner-" + $($('.nav-link.active')[0]).data("id");
    let targetBanner = $(targetBannerId.toString());
    if (targetBanner.length){
      mainBanner.hide();
      if (lastActiveBanner){
        lastActiveBanner.hide();
      }
      lastActiveBanner = targetBanner;
      lastActiveBanner.show();
    }else{
      mainBanner.show();
      if (lastActiveBanner){
        lastActiveBanner.hide();
      }
    }
  }

  //HOMEPAGE VIDEO
  if($(window).width() > 991){
    let videoElements = $("video");
    //mute it first since autoplay policy changes in chrome 66
    videoElements.each(function () {
      if ($(this).hasClass("autoplay")){
        this.muted = true;
        $(this).trigger("play");
      }
    });
  }
});
