import $ from 'jquery'

let page = 1;
let limit = 30;
let unitsUrl;
let $units;
let $loadingSpinner;
let $loadingButton;
let $loadingMoreButton;

$(document).ready(function () {
  // TODO: review this
  $units = $('.units .units-body');
  $loadingSpinner = $('.loading-spinner');
  $loadingButton = $('.loading-btn');
  $loadingMoreButton = $('#load_utils');
  unitsUrl = $loadingButton.data('href');

  $loadingMoreButton.on('click', function () {
    loadUnits(++page, limit);
  });
});

function loadUnits(page = 1, limit = 5) {
  let url = unitsUrl + window.location.search;
  let data = {
    page: page,
    limit: limit,
  };
  $.ajax({
    type: 'GET',
    url: url,
    data: data,
    beforeSend: function () {
      $loadingSpinner.removeClass('d-none');
      $loadingButton.addClass('d-none');
    },
    success: function (data) {
      if (data.hide_button) {
        $loadingButton.hide();
      }
      $units.append(data.view);
    },
    error: function (xhr) {
    },
    complete: function () {
      $loadingSpinner.addClass('d-none');
      $loadingButton.removeClass('d-none');

    },
  });
}
