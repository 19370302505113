$(document).ready(function () {
    let controls = $('.gallery__controls-item');

    controls.on("click", function (e) {
        let el = $(e.currentTarget);
        let direction = el.data('slide-dir');
        let targetGallery = el.closest('.gallery--slide-view');
        slide(direction, targetGallery);
    });

    function slide(direction, targetGallery) {
        let items = targetGallery.children(".image-box");

        items.each(function (i, item) {
            let currentItem = $(item);
            if (currentItem.hasClass('current') && item.classList.contains("current")) {
              let $label = targetGallery.next('.gallery__title-label');

              if (direction === "left") {
                $(items[(items.length + i - 3) % items.length]).addClass('previous-far');

                $(items[(items.length + i - 2) % items.length]).removeClass('previous-far');
                $(items[(items.length + i - 2) % items.length]).addClass('previous');

                $(items[(items.length + i - 1) % items.length]).removeClass('previous');
                $(items[(items.length + i - 1) % items.length]).addClass('current');

                // update the label
                $label.text($(items[(items.length + i - 1) % items.length]).find('img').attr('alt'));

                currentItem.removeClass('current');
                currentItem.addClass('next');

                $(items[(items.length + i + 1) % items.length]).removeClass('next');
                $(items[(items.length + i + 1) % items.length]).addClass('next-far');

                $(items[(items.length + i + 2) % items.length]).removeClass('next-far');
              } else if (direction === "right") {
                $(items[(items.length + i - 2) % items.length]).removeClass('previous-far');

                $(items[(items.length + i - 1) % items.length]).removeClass('previous');
                $(items[(items.length + i - 1) % items.length]).addClass('previous-far');

                currentItem.removeClass('current');
                currentItem.addClass('previous');

                $(items[(items.length + i + 1) % items.length]).removeClass('next');
                $(items[(items.length + i + 1) % items.length]).addClass('current');

                // update the label
                $label.text($(items[(items.length + i + 1) % items.length]).find('img').attr('alt'));

                $(items[(items.length + i + 2) % items.length]).removeClass('next-far');
                $(items[(items.length + i + 2) % items.length]).addClass('next');

                $(items[(items.length + i + 3) % items.length]).addClass('next-far');
              }
                return false;
            }
        });
    }
});
